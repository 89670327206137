import React from 'react';

import './styles.scss';

export const Button = ({ className, onClick, style, text, url }) => {
  const blurOnClick = (e) => {
    e.currentTarget.blur();
    onClick(e);
  };

  return !!url ? (
    <a
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      className={`btn ${className ?? ''}`}
      style={style}
      onClick={blurOnClick}
      onMouseUp={(e) => {
        e.currentTarget.blur();
      }}
    >
      <div className='btn-text-wrapper'>{text}</div>
    </a>
  ) : (
    <div
      className={`btn ${className ?? ''}`}
      style={style}
      onClick={blurOnClick}
    >
      <div className='btn-text-wrapper'>{text}</div>
    </div>
  );
};
