import YouTube from 'react-youtube';
import useViewport from '../../hooks/useViewport.js';

import './styles.scss';

export const YouTubeVideo = ({ videoId }) => {
  const { width } = useViewport();
  const opts = {
    height: width < 428 ? '169' : width > 428 && width < 700 ? '240' : '300',
    width: width < 428 ? '300' : width > 428 && width < 700 ? '426' : '533',
    videoId: videoId,
    playerVars: {
      autoplay: 0,
      controls: 1,
      showinfo: 0,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};
