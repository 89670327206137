import { useLanguageContext } from '../context/Language';
import { copy } from './language';

import './styles.scss'

export const TeamMember = ({ name, title, idx, img }) => {
  const { language } = useLanguageContext();
  const languageObj = copy[language];

  return (idx !== 14 ?
    <div className='teammember-wrapper'>
      <div className='teammember-image-txt-wrapper'>
        <div className='teammember-image' style={img ? { backgroundImage: `url(${require(`../../assets/${img}`)})`}  : {}}></div>
        <div className='teammember-txt-wrapper'>
          <div className='teammember-name'>{name}</div>
          <div className='teammember-title'>{title}</div>
        </div>
      </div>
    </div> :
    <div className='teammember-wrapper'>
      <div className='teammember-image-txt-wrapper'>
        <div className='teammember-image-orange'>
          <h1 className='teammember-title'>
            {languageObj['key1'].toUpperCase()}
          </h1>
          <div className='teammember-info'>
            {languageObj['key2']}
          </div>
          <div className='teammember-email'>
            bmf@barbershop.de
          </div>
          </div>
        <div className='teammember-txt-wrapper white'>
          <div className='teammember-name'>{name}</div>
          <div className='teammember-title'>{title}</div>
        </div>
      </div>
    </div>
  );
}
