export const copy = {
  en: {
    key1: 'GERMAN BARBERSHOP CHAMPIONSHIPS',
    key2: 'BinG! Barbershop in Germany e.V. hosts barbershop events throughout the year. The biggest event is the German Barbershop Championships for choruses and quartets, which take place every two years as part of the Barbershop Music Festival.',
    key3: 'This year, the quartet championships will be held with a semi-final and final round. ',
    key4: 'The current rules can be found ',
    key5: 'HERE',
    key6: '.',
    key7: 'ORDER OF APPEARANCE',
    key8: 'German Barbershop Championship for quartets - ',
    key9: 'Semifinals',
    key10: 'Mic Warmer: ',
    key11: 'SomeSing',
    key12: 'Mic Cooler: ',
    key13: 'Clementones',
    key14:
      'Following the semi-final, the top 6 quartets moving forward to the final round will be announced',
    key15: '',
    key16: 'German Barbershop Championship for choruses',
    key17: 'Mic Cooler: ',
    key18: 'Heavy Medal',
    key19: 'Afterwards chorus results and awards',
    key20: 'German Barbershop Championship for quartets - ',
    key21: 'Finals',
    key22:
      'The order of appearcance as announced on Friday after the quartet semi-finals',
    key23:
      'The judging panel for this years competition is made up as follows:',
    key24: 'Friday, 04/19/24, 2 pm',
    key25: 'Saturday, 04/20/24, 12:30 pm',
    key26: 'Afterwards quartet results and awards',
    key27: 'German Barbershop Championship for quartets - ',
    key27a: 'Finals',
    key28: 'Saturday, 04/20/24, 3 pm',
    key29:
      'The order of appearcance as announced on Friday after the quartet semi-finals',
    key30: 'Afterwards quartet results and awards',
    key31: 'Judges 2024',
    key32: '(scratched)',
  },
  de: {
    key1: 'Deutsche Meisterschaften',
    key2: 'BinG! Barbershop in Germany e.V. richtet in regelmäßigen Abständen Quartett- und Chorwettbewerbe für Barbershopensembles aus. Hierunter zählen auch die Deutschen Barbershop Meisterschaften der Chöre und Meisterschaften Quartette welche in der Regel alle zwei Jahre im Rahmen des Barbershop Musikfestivals stattfinden.',
    key3: 'Dieses Jahr werden die Meisterschaften der Quartette zum ersten Mal mit einem Halbfinale und Finale ausgetragen.',
    key4: 'Das aktuelle Regelwerk ist ',
    key5: 'HIER',
    key6: ' zu finden.',
    key7: 'Auftrittsreihenfolge',
    key8: 'Deutsche Meisterschaften der Quartette – ',
    key9: 'Halbfinale',
    key10: 'Mic Warmer: ',
    key11: 'SomeSing',
    key12: 'Mic Cooler: ',
    key13: 'Clementones',
    key14:
      'Anschließend: Auslosung der Auftrittsreihenfolge für die Plätze 1-6 / Finale',
    key15: '',
    key16: 'Deutsche Meisterschaften der Chöre',
    key17: 'Mic Cooler: ',
    key18: 'Heavy Medal',
    key19: 'Anschließend: Ergebnisse und Ehrungen Chöre',
    key20: 'Deutsche Meisterschaften der Quartette - ',
    key21: 'Finale',
    key22:
      'Wettbewerb der Quartette um Platz 1-6 in der am Freitag ausgelosten Reihenfolge',
    key23:
      'Die Jury für die Wettbewerbe setzt sich dieses Jahr wie folgt zusammen:',
    key24: 'Freitag, 19.04.24, 14:00 Uhr',
    key25: 'Samstag, 20.04.24, 12:30 Uhr',
    key26: 'Anschließend: Ergebnisse und Ehrungen Quartette',
    key27: 'Deutsche Meisterschaften der Quartette - ',
    key27a: 'Finale',
    key28: 'Samstag, 20.04.24, 15:00 Uhr',
    key29:
      'Wettbewerb der Quartette um Platz 1-6 in der am Freitag ausgelosten Reihenfolge',
    key30: 'Anschließend: Ergebnisse und Ehrungen Quartette',
    key31: 'Jury 2024',
    key32: '(abgesagt)',
  },
};
