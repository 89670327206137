export const BackButton = () => {
  return (
    <svg
      width={12}
      height={24}
      viewBox="0 0 24 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 2L3 24.1384L22 46"
        stroke="#E67015"
        strokeWidth={4}
        strokeLinecap="round"
      />
    </svg>
  )
}