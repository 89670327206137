import { useLayoutEffect } from 'react';
import { useLanguageContext } from '../context/Language/';
import { copy } from './language';
import { makeid } from '../../helper';
import './styles.scss';

export const Championship = () => {
  const { language } = useLanguageContext();
  const languageObj = copy[language];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const quartetCompetitors = [
    'Newphoria',
    'No Limits',
    ['3.1'],
    'Pen Pals',
    'Hidden Agenda',
    'BAM!',
    'Liedhaber',
    'Sound of July',
    'Escapade',
    'Veto',
    'Barberpapas',
  ];

  const chorusCompetitors = [
    'EKBC',
    'A Cappella Company',
    'Sugar `n Spice',
    'BYC',
    'Ladies First',
    'Women in Black',
    'Herrenbesuch',
  ];

  const judges = [
    {
      category: 'Musicality',
      judges: ['Adam Scott', 'Patrick McAlexander'],
    },
    {
      category: 'Performance',
      judges: ['Greg Caetano', 'Mike Lietke'],
    },
    {
      category: 'Singing',
      judges: ['Anthony Colosimo', 'Rob Mance'],
    },
  ];

  return (
    <div className='champ-wrapper'>
      <div className='champ-welcome-wrapper'>
        <h1 className='champ-header-txt'>
          {languageObj['key1'].toUpperCase()}
        </h1>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key2']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key3']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>
            {languageObj['key4']}
            <span className='orange'>
              <a
                className='ref-link'
                href={'https://cloud.barbershop.de/index.php/s/x3QZ5DL5BFZx7WE'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {languageObj['key5']}
              </a>
            </span>
            {languageObj['key6']}
          </div>
        </div>
      </div>
      <div className='champ-welcome-wrapper'>
        <h3 className='champ-subheader-txt'>
          {languageObj['key7'].toUpperCase()}
        </h3>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>
            {languageObj['key8']}
            <span className='orange'>{languageObj['key9']}</span>
          </div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt light mb-2'>{languageObj['key24']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt-wrapper light orange ital'>
            {languageObj['key10']}
            <span className='champ-txt light white normal'>
              {languageObj['key11']}
            </span>
          </div>
          {quartetCompetitors.map((q, idx) => {
            return typeof q === 'string' ? (
              <div className='champ-txt-wrapper light' key={q + '_' + idx}>
                {q}
              </div>
            ) : (
              <div className='champ-txt-wrapper light' key={q + '_' + idx}>
                <span
                  className='champ-txt light'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <div className='champ-txt light strike'>{q}</div>
                  &ensp;{languageObj['key32']}
                </span>
              </div>
            );
          })}
          <div className='champ-txt-wrapper light orange ital'>
            {languageObj['key12']}
            <span className='champ-txt light white normal'>
              {languageObj['key13']}
            </span>
          </div>
          <div className='champ-txt light ital'>
            {languageObj['key14']}
            <br />
            {languageObj['key15']}
          </div>
        </div>
      </div>
      <div className='champ-welcome-wrapper'>
        <div className='champ-txt-wrapper mt-3'>
          <div className='champ-txt'>{languageObj['key16']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt mb-2 light'>{languageObj['key25']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          {chorusCompetitors.map((c, idx) => {
            return (
              <div className='champ-txt-wrapper light' key={c + '_' + idx}>
                {c}
              </div>
            );
          })}
          <div className='champ-txt-wrapper light orange ital'>
            {languageObj['key17']}
            <span className='champ-txt light white normal'>
              {languageObj['key18']}
            </span>
          </div>
          <div className='champ-txt light ital'>{languageObj['key19']}</div>
        </div>
      </div>
      <div className='champ-welcome-wrapper'>
        <div className='champ-txt-wrapper mt-3'>
          {languageObj['key27']}
          <span className='orange'>{languageObj['key27a']}</span>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt mb-2 light'>{languageObj['key28']}</div>
        </div>
        <div className='champ-txt mb-2 light'>{languageObj['key29']}</div>
        <div className='champ-txt-wrapper light orange ital'>
          {languageObj['key12']}
          <span className='champ-txt light white normal'>
            {languageObj['key13']}
          </span>
        </div>
        <div className='champ-txt mb-2 light ital'>{languageObj['key30']}</div>
      </div>
      <div className='champ-welcome-wrapper'>
        <h3 className='mt-5 mb-2'>{languageObj['key31'].toUpperCase()}</h3>
        <div className='champ-txt-wrapper'>{languageObj['key23']}</div>
        <div className='champ-txt-wrapper'>
          {judges.map((j) => {
            const { category, judges } = j;
            return (
              <div key={makeid(7)}>
                <div className='mb-2 mt-3'>{category}</div>
                {judges.map((j) => {
                  return (
                    <div key={makeid(5)}>
                      <div className='champ-txt light'>{j}</div>
                      <br />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
