const YOUTUBE_PLAYLIST_ITEMS_API =
  'https://www.googleapis.com/youtube/v3/playlistItems';
const YOUTUBE_PLAYLIST_ID = 'PLzf4Vau7WL5nsBjWnH28xlB-0e306cSqk';

const getPlaylistVideos = async () => {
  const res = await fetch(
    `${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&maxResults=50&playlistId=${YOUTUBE_PLAYLIST_ID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
  );
  const data = await res.json();

  return data;
};

export { getPlaylistVideos };
