import { useLayoutEffect } from 'react';
import { useLanguageContext } from '../context/Language/';
import { copy } from './language';

import './styles.scss';

export const OpenStage = () => {
  const { language } = useLanguageContext();
  const languageObj = copy[language];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const quartets = [
    'Aca scuse me (DM)',
    'Nerd Stuff',
    'Mrs. Lovett',
    'SomeSing',
    'KIQ - Kölner Instant Quartett',
    'Last minute',
    'Wash Queens',
    'Shady Ladies ',
    ['O-Ton'],
    'Seriously?!',
  ];

  const choruses = [
    'Barberellas',
    'The Rhubarbs',
    "4'n More",
    'MissHarmony',
    'Harmunichs',
  ];

  const judges = [
    {
      category: 'Musicality',
      judges: ['Adam Scott', 'Patrick McAlexander'],
    },
    {
      category: 'Performance',
      judges: ['Greg Caetano', 'Mike Lietke'],
    },
    {
      category: 'Singing',
      judges: ['Anthony Colosimo', 'Rob Mance'],
    },
  ];

  return (
    <div className='champ-wrapper'>
      <div className='champ-welcome-wrapper'>
        <h1 className='champ-header-txt'>
          {languageObj['key1'].toUpperCase()}
        </h1>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key2']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>
            {languageObj['key4']}
            <span className='orange ref-link'>
              <a
                className='ref-link'
                href={'https://cloud.barbershop.de/index.php/s/x3QZ5DL5BFZx7WE'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {languageObj['key5']}
              </a>
            </span>
            {languageObj['key6']}
          </div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key7']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key8']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key9']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key10']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key11']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key12']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key20']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key21']}</div>
        </div>
        <div className='champ-txt-wrapper mt-3'>
          <h2 className='champ-txt'>{languageObj['key13']}</h2>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key14']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt light mb-2'>{languageObj['key15']}</div>
        </div>
        <div className='champ-txt-wrapper orange ital light'>
          Mic Warmer:{' '}
          <span className='champ-txt light white normal'>Clementones</span>
        </div>
        {quartets.map((q, idx) => {
          return typeof q === 'string' ? (
            <div key={q + '_' + idx} className='champ-txt-wrapper'>
              <div className='champ-txt light'>{q}</div>
            </div>
          ) : (
            <div key={q + '_' + idx} className='champ-txt-wrapper'>
              <span
                className='champ-txt light'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <div className='champ-txt light strike'>{q}</div>
                &ensp;{languageObj['key22']}
              </span>
            </div>
          );
        })}
        <div className='champ-txt-wrapper mt-3'>
          <div className='champ-txt'>{languageObj['key16']}</div>
        </div>
        <div className='champ-txt-wrapper light mb-2'>
          <div className='champ-txt mb-2'>{languageObj['key17']}</div>
        </div>
        <div className='champ-txt-wrapper orange ital light'>
          Mic Warmer:{' '}
          <span className='champ-txt light white normal'>
            Projektchor Lucky Dreamers
          </span>
        </div>
        {choruses.map((q, idx) => {
          return (
            <div key={q + '_' + idx} className='champ-txt-wrapper'>
              <div className='champ-txt light'>{q}</div>
            </div>
          );
        })}
      </div>
      <div className='champ-welcome-wrapper'>
        <h3 className='mt-5 mb-2'>{languageObj['key19'].toUpperCase()}</h3>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key18']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          {judges.map((j, idx) => {
            const { category, judges } = j;
            return (
              <div key={category + '_' + idx} className='mb-2'>
                <div className='mb-2'>{category}</div>
                {judges.map((j, idx) => {
                  return (
                    <>
                      <div className='champ-txt light'>{j}</div>
                      <br />
                    </>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
