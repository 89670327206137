
import './styles.scss';

export const Hotel = ({ name, street, city, phone, email, url, googleMapsLink }) => {
  const displayUrl = url.split('https://')[1];
  return (
    <div className='hotel-wrapper'>
      <h3 className='hotel-name'>{name?.toUpperCase()}</h3>
      <a className='hotel-address txt' href='https://maps.app.goo.gl/gFH9nfBVafYmoBAX8' target='_blank' rel='noopener noreferrer'>
        <div className='hotel-street'>{street}</div>
        <div className='hotel-city'>{city}</div>
      </a>
      <div className='hotel-contact txt'>
        <div className='hotel-phone'>{phone}</div>
        <div className='hotel-email'>{email}</div>
        <a className='hotel-url' href={url} target='_blank' rel='noopener noreferrer'>{displayUrl}</a>
      </div>
    </div>
  )
}