import { useLanguageContext }  from '../context/Language/';
import { copy } from './language';

import './styles.scss';

export const PageNotFound = () => {
  const { language } = useLanguageContext();
  const languageObj = copy[language];
 
  return (
    <div className='page-not-found-wrapper'>
      <h1>{languageObj['key1']}{' :('}</h1>
    </div>
  );
}