import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import { TeamMember } from '../TeamMember';
import { useLanguageContext } from '../context/Language/';
import { copy } from './language';

import './styles.scss';

export const Home = () => {
  const { language } = useLanguageContext();
  const languageObj = copy[language];
  const navigate = useNavigate();
  const routeChange = (e, link) => {
    if (!link) return;
    navigate(link);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const btns = [
    {
      text: {
        de: 'Deutsche Barbershop Meisterschaften',
        en: 'German Barbershop Championships',
      },
      link: '/deutsche-meisterschaften',
      className: 'mt-0 fg-1',
    },
    {
      text: {
        de: 'Festival Open Stage',
        en: 'Festival Open Stage',
      },
      link: '/open-stage',
      className: 'mt-0 fg-1',
    },
    {
      text: {
        de: 'Locations, Unterkunft und Anreise',
        en: 'Locations, Accommodation and Travel',
      },
      link: '/travel-accommodations',
      className: 'mt-0 fg-1',
    },
    {
      text: {
        de: 'Tickets',
        en: 'Tickets',
      },
      link: '',
      url: 'https://events.barbershop.de/bmf2024visit/',
      className: 'mt-0 fg-1',
    },
    {
      text: {
        de: 'Registrierung',
        en: 'Registration',
      },
      link: '',
      url: 'https://events.barbershop.de/bmf2024register/',
      className: 'mt-0 fg-1',
    },
  ];

  const teamMembers = [
    {
      name: 'Julia Moldenhauer',
      title: { de: 'Vorstand, Koordination BMF', en: 'Board, BMF Coordinator' },
      img: 'Julia_Moldauer.png',
    },
    {
      name: 'Almut Schmitz',
      title: { de: 'Vorstand, Koordination BMF', en: 'Board, BMF Coordinator' },
      img: 'Almut_Schmitz.png',
    },
    {
      name: 'Birgitta Bender',
      title: {
        de: 'Geschäftsstelle und Infotheke',
        en: 'Office Management / Infostand',
      },
      img: 'Birgitta_Bender.png',
    },
    {
      name: 'Friedel Petring',
      title: {
        de: 'Hotelorganisation & Hostessen',
        en: 'Hotel Organization & Hostesses ',
      },
      img: 'Friedel_Petring.png',
    },
    {
      name: 'Franziska Dickhut',
      title: {
        de: 'Hotelorganisation für Teilnehmer:innen',
        en: 'Hotel Organization for Participants',
      },
      img: 'Franziska_Dickhut.png',
    },
    {
      name: 'Mona Gebhart',
      title: { de: 'Reiseplanung', en: 'Travel Planning' },
      img: 'Mona_Gebhart.png',
    },
    {
      name: 'Matthias Kriegesmann',
      title: { de: 'Technik / Logistik', en: 'Technology / Logistics' },
      img: 'Matthias_Kriegesmann.png',
    },
    {
      name: 'Stephan Ahrends',
      title: { de: 'Technik / Logistik', en: 'Technology / Logistics' },
      img: 'Stephan_Ahrends.png',
    },
    {
      name: 'Alexander Schuh',
      title: { de: 'Backstagemanagement', en: 'Backstage Management' },
      img: 'AlexnDER_Schuh.png',
    },
    {
      name: 'Hélène Moëlo',
      title: {
        de: 'Ticketshopbetreuung & Jury Assistenz',
        en: "Ticket Shop Support & Judges' Support",
      },
      img: 'Helen_Moelo.png',
    },
    {
      name: 'Hannah Ostendarp',
      title: { de: 'Jury Betreuung', en: "Judges' Support" },
      img: 'Hannah_Ostendarp.png',
    },
    {
      name: 'Hartmut Otto',
      title: { de: 'Jury Vorsitz', en: 'Contest Administrator' },
      img: 'Harmut_Otto.png',
    },
    {
      name: 'Dr. Julia Stötzel',
      title: {
        de: 'Finanzen und Helfer:innenkoordination',
        en: 'Finances and Helper Coordination',
      },
      img: 'Julia_Stoetzel.png',
    },
    {
      name: 'Mira Taferner',
      title: { de: 'Design', en: 'Design' },
      img: 'Mira_Taferner.png',
    },
    { name: 'XXXXXXXXX', title: { de: 'xxxxx', en: 'xxxxx' }, img: '' },
  ];

  return (
    <div className='home-wrapper'>
      <div className={`homepage-img ${language}`}></div>
      <div className='homepage-welcome-wrapper'>
        <h1 className='homepage-header-txt'>
          {languageObj['key44'].toUpperCase()}
        </h1>
        <div className='homepage-txt-wrapper'>
          <div className='homepage-txt'>{languageObj['key45']}</div>
          <div className='homepage-txt'>{languageObj['key46']}</div>
        </div>
        <div className='homepage-txt-wrapper'>
          <div className='homepage-txt'>{languageObj['key47']}</div>
        </div>
        <div className='homepage-txt-wrapper mt-1'>
          <div className='homepage-txt'>
            {languageObj['key48'].toUpperCase()}
          </div>
        </div>
        <div className='homepage-txt-wrapper'>
          <div className='homepage-txt'>
            <span className='orange'>{languageObj['key49']}</span>
            {languageObj['key50']}
          </div>
        </div>
        <div className='homepage-txt-wrapper'>
          <div className='homepage-txt'>
            <span className='orange'>{languageObj['key51']}</span>
            {languageObj['key52']}
          </div>
        </div>
        <div className='homepage-txt-wrapper'>
          <div className='homepage-txt'>
            <span className='orange'>{languageObj['key53']}</span>
            {languageObj['key54']}
          </div>
        </div>
        <div className='homepage-txt-wrapper mt-2'>
          <div className='homepage-txt'>
            {languageObj['key55'].toUpperCase()}
          </div>
        </div>
        <div className='homepage-txt-wrapper'>
          <div className='homepage-txt'>
            <span className='orange'>{languageObj['key49']}</span>
            {languageObj['key56']}
          </div>
        </div>
        <div className='homepage-txt-wrapper'>
          <div className='homepage-txt'>
            <span className='orange'>{languageObj['key51']}</span>
            {languageObj['key57']}
          </div>
        </div>
        <div className='homepage-txt-wrapper'>
          <div className='homepage-txt'>
            <span className='orange'>{languageObj['key53']}</span>
            {languageObj['key58']}
          </div>
        </div>
        <div className='homepage-txt-wrapper mt-2'>
          <div className='homepage-txt'>{languageObj['key59']}</div>
        </div>
      </div>
      <div className='homepage-btn-wrapper mt-2'>
        <Button
          className='mt-0 fg-1'
          text={languageObj['key41']}
          onClick={(e) => routeChange(e, '/media')}
          url={''}
        />
        <Button
          className='mt-0 fg-1'
          text={languageObj['key42']}
          onClick={(e) => {}}
          url={'https://cloud.barbershop.de/index.php/s/9ByJKQgTFQb5XRJ'}
        />
        <Button
          className='mt-0 fg-1'
          text={languageObj['key43']}
          onClick={(e) => {}}
          url={'https://cloud.barbershop.de/index.php/s/fHAoQmKxjF2s9Hp'}
        />

        {/* {btns.map((btn, idx) => (
          <Button
            className={btn.className}
            key={`${btn.link + '-' + idx}`}
            text={btn.text[language]}
            onClick={(e) => routeChange(e, btn.link)}
            url={btn.url}
          />
        ))} */}
      </div>
      <div className='homepage-program-wrapper'>
        <h1 className='homepage-txt-wrapper orange mt-2 mb-1'>
          {'Save the date!!!'.toUpperCase()}
        </h1>
        <div className='homepage-txt-wrapper mtb-1'>
          <div className='homepage-txt'>{languageObj['key60']}</div>
        </div>
        <div className='homepage-txt-wrapper mtb-half fl-2'>
          <h1 className='homepage-txt'>{languageObj['key61']}</h1>
        </div>
        <div className='homepage-txt-wrapper mtb-1 mb-3'>
          <div className='homepage-txt'>{languageObj['key62']}</div>
        </div>
      </div>
      <div className='homepage-welcome-wrapper mt-2'>
        <h1 className='homepage-header-txt orange'>
          {languageObj['key63'].toUpperCase()}
        </h1>
        <div className='homepage-txt-wrapper mtb-1'>
          <div className='homepage-txt'>{languageObj['key64']}</div>
        </div>
        <div className='homepage-txt-wrapper mtb-1'>
          <div className='homepage-txt'>{languageObj['key65']}</div>
        </div>

        <div className='byc-img-txt-wrapper'>
          <div className='byc-img-wrapper mr-3'>
            <div className='byc-img'></div>
          </div>
          <div className='byc-pp-txt-wrapper'>
            <div className='byc-pp-txt-img-wrapper mr-1'>
              <div className='byc-pp-txt orange mb-1'>
                {languageObj['key66']}
              </div>
              <div className='byc-pp-img-wrapper'>
                <div className='byc-pp-img'></div>
              </div>
            </div>
            <div className='byc-pp-txt-wrapper orange ml-1'>
              <div className='byc-txt'>{languageObj['key67']}</div>
              <div className='byc-txt mtb-1 fl-2 underline under'>
                <a
                  className='byc-pp-txt'
                  href='https://www.paypal.com/donate/?hosted_button_id=C6LUGAS6W8BVY'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {languageObj['key68'].toUpperCase()}
                </a>
              </div>
              <div className='byc-txt'>{languageObj['key69']}</div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='homepage-program-wrapper ta-c'>
        <h1 className='homepage-txt-wrapper orange mt-2 width8'>
          {languageObj['key71'].toUpperCase()}
        </h1>
        <div className='homepage-txt-wrapper mtb-1 mlr-1'>
          <div className='homepage-txt'>{languageObj['key72']}</div>
        </div>
        <div className='homepage-txt-wrapper mtb-1 mb-3'>
          <div className='homepage-txt'>
            <a
              className='under black'
              href='www.google.com'
              target='_blank'
              rel='noreferrer'
            >
              {languageObj['key73'].toUpperCase()}
            </a>
          </div>
        </div>
      </div> */}

      <div className='homepage-spreadshirt-wrapper'>
        <h1 className='homepage-program-header spreadshirt'>
          {'Get The Look'.toUpperCase()}
        </h1>
        <div className='homepage-spreadshirt-txt-wrapper'>
          <div className='homepage-spreadshirt-txt'>
            {languageObj['key34']} <b className='bold'>»A CAPPELLA LOVE«</b>{' '}
            {languageObj['key35']}
          </div>
          <div className='homepage-spreadshirt-txt'>{languageObj['key36']}</div>
          <div className='homepage-spreadshirt-txt'>{languageObj['key37']}</div>
        </div>
        <div className='homepage-spreadshirt-txt-wrapper'>
          <div className='homepage-spreadshirt-txt'>
            <a
              className='homepage-spreadshirt-link orange'
              href='https://bing.myspreadshop.de'
              target='_blank'
              rel='noreferrer'
            >
              www.bing.myspreadshop.de
            </a>
          </div>
        </div>
        <div className='homepage-spreadshirt-img-wrapper'>
          <a
            className='homepage-spreadshirt-link'
            href='https://bing.myspreadshop.de'
            target='_blank'
            rel='noreferrer'
          >
            <div className='homepage-spreadshirt-img'></div>
          </a>
        </div>
        <div className='homepage-spreadshirt-cc-txt'>
          &#169; Eric Ideler (Thank you Eric for all the great footage you
          capture)
        </div>
      </div>

      <div className='homepage-video-wrapper'>
        <h1 className='homepage-video-header'>
          {languageObj['key29'].toUpperCase()}
        </h1>
        <div className='homepage-video-container'>
          <iframe
            className='homepage-video'
            src='https://www.youtube.com/embed/abjv6sbf8Js?si=XisdsGvG5Ejs4fEa'
            title='Das war das Barbershop Musikfestival 2023!'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy='strict-origin-when-cross-origin'
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className='homepage-program-wrapper'>
        <h1 className='homepage-program-header'>
          {languageObj['key5'].toUpperCase()}
        </h1>
        <div className='homepage-program-days-wrapper'>
          <div className='homepage-program-day-wrapper'>
            <h2 className='homepage-program-dow'>
              {languageObj['key6'].toUpperCase()}
            </h2>
            <h2 className='homepage-program-dow date'>{'18.04.24'}</h2>
            <div className='homepage-program-content-header'>Get Together</div>
            <div className='homepage-program-content top'>
              {languageObj['key7']}
            </div>
            <div className='homepage-program-content top'>
              {languageObj['key8']}
            </div>
          </div>
          <div className='homepage-program-day-wrapper'>
            <h2 className='homepage-program-dow'>
              {languageObj['key9'].toUpperCase()}
            </h2>
            <h2 className='homepage-program-dow date'>{'19.04.24'}</h2>
            <div className='homepage-program-content-header'>
              Festival Open Stage
              <br />
              {languageObj['key14']}
            </div>
            <div className='homepage-program-content top'>
              {languageObj['key15']}
            </div>
            <div className='homepage-program-content top'>
              Goldsaal - Westfalenhalle
            </div>
            <div className='homepage-program-content-line'></div>
            <div className='homepage-program-content-header'>
              {languageObj['key10']}
              <br />
              {languageObj['key11']}
            </div>
            <div className='homepage-program-content-header'>
              {languageObj['key12']}
            </div>
            <div className='homepage-program-content top'>
              {languageObj['key13']}
            </div>
            <div className='homepage-program-content top'>
              Goldsaal - Westfalenhalle
            </div>
          </div>
          <div className='homepage-program-day-wrapper'>
            <h2 className='homepage-program-dow'>
              {languageObj['key16'].toUpperCase()}
            </h2>
            <h2 className='homepage-program-dow date'>{'20.04.24'}</h2>
            <div className='homepage-program-content-header'>
              Festival Open Stage
              <br />
              {languageObj['key19']}
            </div>
            <div className='homepage-program-content top'>
              {languageObj['key17']}
            </div>
            <div className='homepage-program-content top'>
              Goldsaal - Westfalenhalle
            </div>
            <div className='homepage-program-content-line'></div>
            <div className='homepage-program-content-header'>
              {languageObj['key18']}
              <br />
              {languageObj['key19']}
            </div>
            <div className='homepage-program-content top'>
              {languageObj['key20']}
            </div>
            <div className='homepage-program-content top'>
              Goldsaal - Westfalenhalle
            </div>
            <div className='homepage-program-content-line'></div>
            <div className='homepage-program-content-header'>
              {languageObj['key18']}
              <br />
              {languageObj['key11']}
            </div>
            <div className='homepage-program-content-header'>
              {languageObj['key21']}
            </div>
            <div className='homepage-program-content top'>
              {languageObj['key22']}
            </div>
            <div className='homepage-program-content top'>
              Goldsaal - Westfalenhalle
            </div>
            <div className='homepage-program-content-line'></div>
            <div className='homepage-program-content-header'>
              {languageObj['key23']}
            </div>
            <div className='homepage-program-content top'>
              {languageObj['key24']}
            </div>
          </div>
          <div className='homepage-program-day-wrapper'>
            <h2 className='homepage-program-dow'>
              {languageObj['key25'].toUpperCase()}
            </h2>
            <h2 className='homepage-program-dow date'>{'21.04.24'}</h2>
            <div className='homepage-program-content-header'>
              {languageObj['key26']}
            </div>
            <div className='homepage-program-content top'>
              {languageObj['key26a']}
            </div>
            <div className='homepage-program-content top'>
              Goldsaal - Westfalenhalle
            </div>
          </div>
        </div>
        <div className='homepage-program-footer-wrapper'>
          <div>
            {languageObj['key27']}{' '}
            <span>
              <a
                className='orange underline'
                href={'https://cloud.barbershop.de/index.php/s/fHAoQmKxjF2s9Hp'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {languageObj['key28']}
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className='homepage-spreadshirt-wrapper guest'>
        <h1 className='homepage-program-header spreadshirt'>
          {languageObj['key38'].toUpperCase()}
        </h1>
        <div className='homepage-spreadshirt-txt-wrapper'>
          <div className='homepage-txt'>{languageObj['key39']}</div>
        </div>
        <div className='homepage-spreadshirt-txt-wrapper'>
          <div className='homepage-txt'>{languageObj['key40']}</div>
        </div>
        <div className='homepage-spreadshirt-img-wrapper guest'>
          <a
            className='homepage-spreadshirt-link'
            href='https://www.facebook.com/clementones/'
            target='_blank'
            rel='noreferrer'
          >
            <div className='homepage-guest-img'></div>
          </a>
        </div>
      </div>

      <div className='homepage-btn-wrapper mt-2'>
        {btns.map((btn, idx) => (
          <Button
            key={`${btn.link + '-' + idx}`}
            text={btn.text[language]}
            onClick={(e) => routeChange(e, btn.link)}
            url={btn.url}
          />
        ))}
      </div>

      <div className='homepage-program-wrapper'>
        <h1 className='homepage-program-header'>
          {languageObj['key30'].toUpperCase()}
        </h1>
        <div className='homepage-program-header-txt'>
          {languageObj['key31']}
        </div>
        <div className='homepage-program-header-txt last'>
          {languageObj['key32']}
          <br />
          {languageObj['key33']}
        </div>
        <div className='homepage-team-wrapper'>
          {teamMembers.map((member, idx) => (
            <TeamMember
              idx={idx}
              key={`member-title-${idx}`}
              name={member.name}
              title={member.title[language]}
              img={member.img}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
