import { useLanguageContext }  from '../context/Language/';
import { copy } from './language';

import './styles.scss';

export const Event = ({ place }) => {
  const { language  } = useLanguageContext();
  const languageObj = copy[language];

  return (place === 'first' ?
    <div className='event-wrapper'>
      <div className='event-img-wrapper'>
        <div className={`event-img ${place}`}></div>
      </div>
      <div className='event-text-wrapper'>
        <h2 className='event-text-title'>GOLDSAAL<br/>WESTFALENHALLE</h2>
        <div className='event-text-address'>
          <a className='event-text-link' href='https://maps.app.goo.gl/ZwAsEe4yJaAYVBmX8' target='_blank' rel='noopener noreferrer'>
            <div className='event-text-address-street'>Strobelallee 41</div>
            <div className='event-text-address-city'>44139 Dortmund</div>
          </a>
        </div>
        <div className='event-text-details'>
          <div className='event-text-details-date'>{languageObj['key3'].toUpperCase()}</div>
          <div className='event-text-details-event'>{languageObj['key1']}<br />{languageObj['key2']}</div>
        </div>
      </div>
    </div> :
    <div className='event-wrapper'>
    <div className='event-img-wrapper'>
      <div className={`event-img ${place}`}></div>
    </div>
    <div className='event-text-wrapper'>
      <h2 className='event-text-title'>STROBELS</h2>
      <div className='event-text-address'>
        <a className='event-text-link' href='https://maps.app.goo.gl/VmBFZEgxe8ytBaHu6' target='_blank' rel='noopener noreferrer'>
          <div className='event-text-address-street'>Strobelallee 50</div>
          <div className='event-text-address-city'>44139 Dortmund</div>
        </a>
      </div>
      <div className='event-text-details'>
        <div className='event-text-details-date'>{languageObj['key6'].toUpperCase()}</div>
        <div className='event-text-details-event'>{languageObj['key7']}</div>
      </div>
    </div>
  </div>
  )
}