export const copy = {
  en: {
    key1: "IT'S THAT TIME AGAIN!",
    key2: "Less than a year after our last Barbershop Music Festival we're back! Time to rock that barbershop stage in Germany!",
    key3: "This website provides all the necessary information to register, purchase tickets for friends and family, and everything you need to know about this year's event.",
    key4: 'Have fun browsing!',
    key5: 'Program Overview',
    key6: 'Thursday',
    key7: '7 pm',
    key8: 'Strobels',
    key9: 'Friday',
    key10: 'German Barbershop Championship',
    key11: 'for quartets',
    key12: '- Semifinals -',
    key13: '2 pm ',
    key14: 'quartets',
    key15: '11 am',
    key16: 'SATURDAY',
    key17: '11 am ',
    key18: 'German Barbershop Championship',
    key19: 'for choruses',
    key20: '12:30 pm',
    key21: '- Finals -',
    key22: '3 pm',
    key23: 'Show of the Champions',
    key24: '7:30 pm',
    key25: 'SUNDAY',
    key26: 'Closing Ceremony',
    key26a: '12:00 - 12:30 pm',
    key27:
      'Detailed information about ensembles, judges and more can be found in our program.',
    key28: 'Download now!',
    key29: 'Recap BMF 2023',
    key30: 'The Event Team',
    key31: 'Got questions? Write us an email at bmf@barbershop.de',
    key32:
      'For assistance in regards to hotels and lodging, please contact our hotel coordinator directly: ',
    key33: 'Friedel Petring (friedelpetring@web.de).',
    key34: 'You love our',
    key35: ' slogan? So do we!',
    key36:
      'Just imagine how great it would look on a tshirt or a hoodie... or maybe a mug?',
    key37: "No need to imagine! We've got you! :)",
    key38: "THIS YEAR'S GUEST ENSEMBLE",
    key39:
      'Clementones is a Danish barbershop quartet founded in 2018 out of the city Aarhus. The quartet consists of Erik, Oliver, Malte and Erik. Yes, you read that right - 2 Eriks on the flanks! In 2022 they  were lucky enough to run away with the Varsity championship in Charlotte and in 2023 they finished 20th in the open competition.',
    key40:
      'This year they will be returning to the international contest stage in Cleveland, and with a lot of totally new contest arrangements, which they will premiere at BinG!',
    key41: 'Media / Videos',
    key42: 'Program',
    key43: 'Scoresheets',
    key44: 'What a Celebration',
    key45: "And before you can blink, it's over.",
    key46:
      "Congratulations to all the ensembles that took to the stage over the weekend and knocked our audience's socks off. You were a such a joy to watch and listen to, and we couldn't have asked for a better birthday party.",
    key47: 'A special congratuliations to our medal winners:',
    key48: 'German Barbershop Championship for Choruses',
    key49: '1st Place: ',
    key50: 'BinG! Youth Chorus',
    key51: '2nd Place: ',
    key52: 'Herrenbesuch',
    key53: '3rd Place: ',
    key54: 'Ladies First',
    key55: 'German Barbershop Championship for Quartets',
    key56: 'Veto',
    key57: 'Barberpapas',
    key58: 'Escapade',
    key59:
      "Still haven't had your fill yet? Browse through the media library, the program booklet or analyze the scoresheets.",
    key60: 'The next BMF will take place from',
    key61: 'March 12 - 15, 2026',
    key62: 'in the Stadthalle Wuppertal!',
    key63: 'Support Our Youth',
    key64:
      'We would like to take the well-deserved victory of our youth choir at the German Championship of Barbershop Choirs as an opportunity to ask for your support of our youth.',
    key65:
      "The BinG! Youth Chorus, which is it's own organzation, is strictly funded from donations from kind folks like you. If you enjoyed what these 50 wonderful singers did, not only on stage but also in preparation for this event, they would love any and all financial support you can muster to continue this wonderful legacy. The money is used for a plethora of things including purchasing sheet music, helping defray costs of weekend coachings and rehearsing, and to keep the Youth Chorus in good financial standing for the future of BinG!.",
    key66: 'Scan me',
    key67: 'Or click',
    key68: 'here',
    key69: 'to donate directly via PayPal',
    key71: 'Your opinion matters',
    key72:
      'In order to make the next BMF even better, we would love your feedback.',
    key73: 'To the Survey',
    key74: '',
    key75: '',
    key76: '',
    key77: '',
    key78: '',
    key79: '',
  },
  de: {
    key1: 'ES IST WIEDER SO WEIT!',
    key2: 'Nur ein knappes Jahr nach dem letzten Barbershop Musikfestival heißt es wieder: Bühne frei für Barbershop in Germany!',
    key3: 'Auf dieser Seite finden Sie alle nötigen Informationen, um sich zu registrieren, Tickets für Freunde und Bekannte zu erwerben und um mehr über die Events des Barbershop Musikfestivals 2024 zu erfahren.',
    key4: 'Viel Spaß beim Stöbern!',
    key5: 'Programm Übersicht',
    key6: 'Donnerstag',
    key7: 'ab 19:00 Uhr',
    key8: 'Strobels',
    key9: 'Freitag',
    key10: 'Deutsche Barbershop Meisterschaft',
    key11: 'der Quartette',
    key12: '- Halbfinale -',
    key13: '14:00 Uhr',
    key14: 'Quartette',
    key15: '11:00 Uhr',
    key16: 'SAMSTAG',
    key17: '11:00 Uhr ',
    key18: 'Deutsche Barbershop Meisterschaft',
    key19: 'der Chöre',
    key20: '12:30 Uhr ',
    key21: '- Finale -',
    key22: '15:00 Uhr',
    key23: 'Show der Champions',
    key24: '19:30 Uhr',
    key25: 'SONNTAG',
    key26: 'Abschlussveranstaltung',
    key26a: '12:00 - 12:30 Uhr',
    key27:
      'Detaillierte Infos zu Ensembles, Jury und mehr finden Sie im Programmheft. ',
    key28: 'Jetzt downloaden!',
    key29: 'Rückblick BMF 2023',
    key30: 'Das Event-Team',
    key31:
      'Sie haben Fragen? Schreiben Sie uns gerne eine E-Mail unter bmf@barbershop.de',
    key32:
      'Sollten Sie Fragen zum Thema Hotels haben, wenden Sie sich bitte direkt an',
    key33: 'Friedel Petring (friedelpetring@web.de).',
    key34: 'Sie lieben den',
    key35: 'Slogan? Wir auch!',
    key36:
      'Wie cool wäre der auf einem T-Shirt oder Hoodie... oder einer Tasse?',
    key37: 'Kein Problem! Wir haben da mal was vorbereitet! :)',
    key38: 'UNSER DIESJÄHRIGES GASTENSEMBLE',
    key39:
      'Clementones ist ein 2018 gegründetes dänisches Barbershop-Quartett aus der Stadt Aarhus. Das Quartett besteht aus Erik, Oliver, Malte und Erik. Ja, du hast richtig gelesen - 2 Eriks! Im Jahr 2022 gewannen sie verdient die Varsity-Meisterschaft in Charlotte und 2023 belegten sie den 20. Platz bei den BHS Internationals.',
    key40:
      'Dieses Jahr kehren sie auf die internationale Wettbewerbsbühne in Cleveland zurück, und zwar mit brandneuen Wettbewerbsarrangements, die sie beim BMF erstmals vor Publikum singen werden!',
    key41: 'Mediathek',
    key42: 'Programmheft',
    key43: 'Scoresheets',
    key44: 'Es war ein fest',
    key45: 'Und schwups, ist es wieder vorbei.',
    key46:
      'Wir gratulieren alle Ensembles, die am Wochenende die Bühne betreten und unser Publikum von den Socken gehauen haben. Ihr wart ein Fest für Ohr und Auge, und wir hätten uns keine bessere Geburtstagsparty wünschen können.',
    key47: 'Ein besonderer Glückwunsch geht an unsere Medaillengewinner:',
    key48: 'Deutsche Meisterschaften der Chöre',
    key49: '1. Platz: ',
    key50: 'BinG! Youth Chorus',
    key51: '2. Platz: ',
    key52: 'Herrenbesuch',
    key53: '3. Platz: ',
    key54: 'Ladies First',
    key55: 'Deutsche Meisterschaften der Quartette',
    key56: 'Veto',
    key57: 'Barberpapas',
    key58: 'Escapade',
    key59:
      'Noch nicht bereit aus der Bubble auszusteigen? Stöbern Sie in der Mediathek, dem Programmheft oder analysieren Sie die Scoresheets.',
    key60: 'Das nächste BMF findet vom',
    key61: '12. - 15. März 2026',
    key62: 'in der Stadthalle Wuppertal statt!',
    key63: 'Unterstützen sie unsere Jugend',
    key64:
      'Wir möchten den sehr verdienten Sieg unseres Jugendchores bei der Deutsche Meisterschaft der Barbershopchöre zum Anlass nehmen, um die Unterstützung unserer Jugendarbeit zu werben.',
    key65:
      'Die BinG! Chorjugend bildet einen eigenen Verein, der sich ausschließlich über Spenden und Fördermittel finanziert. Wenn Ihnen und Euch gefallen hat, was diese 50 jungen Menschen auf der Bühne und im Hintergrund eigenverantwortlich und höchst engagiert leisten, freut sich die BinG! Chorjugend über jeden Euro, um die Arbeit fortsetzen zu können. Das Geld fließt beispielsweis in die Anschaffung von Noten, die Finanzierung von Probenwochenenden und Stipendien und wird ausschließlich für satzungsgemäße Zwecke verwendet.',
    key66: 'Scan mich',
    key67: 'Oder klicke',
    key68: 'hier',
    key69: 'um direkt über PayPal zu spenden',
    key71: 'Ihre Meinung ist uns Wichtig',
    key72:
      'Um das nächste BMF noch besser zu machen, freuen wir uns über Feedback.',
    key73: 'Zur Umfrage',
    key74: '',
    key75: '',
    key76: '',
    key77: '',
    key78: '',
    key79: '',
  },
};
