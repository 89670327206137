export const copy = {
  en: {
    key1: 'LOCATIONS, ACCOMODATION AND TRAVEL',
    key2: "We are happy that the City of Dortmund will be hosting us for this year's festival once more.",
    key3: 'Below you will find information on the event location as well as hotels near by.',
    key4: 'Event Locations',
    key5: 'Active members and their travel companions (supporters) will be able to book reserved hotel rooms. To get acces to these rooms, you must contact the hotel individually and use the appropriate booking code.',
    key6: 'We will email a price list as well as the necessary booking codes to all members!',
    key7: 'Travel',
    key8: 'The ',
    key9: 'website of our event location',
    key10: ' offers detailed information on how to get to the venue.',
    key11a: 'Visit ',
    key11b: 'www.bus-und-bahn.de',
    key11c: ' for information on bus and train schedules and ticket prices.',
    key11:
      'Below we listed some useful information regarding traveling to Dormund:',
    key12: 'BY TRAIN',
    key13:
      'When traveling domestically, it makes sense to use the Deutsche Bahn. They offer direct connections (or max. 1 transfer) from the following cities:',
    key14: 'approx. 6 h',
    key15: 'approx. 3.5 h',
    key16: 'approx. 2.5 h',
    key17: 'approx. 2.5 h',
    key18: 'approx. 6 h',
    key19: 'BY PLANE',
    key20:
      "Dortmund Airport (DTM) can be reached domestically from Munich. There are other European destinations that fly to Dortmund. For further information please visit the airport's webpage. With the AirportExpress you can reach Dortmund Central Station in only 25 minutes.",
    key21: '',
    key22: '',
    key23: '',
  },
  de: {
    key1: 'LOCATIONS, UNTERKUNFT UND ANREISE',
    key2: 'Auch für das Barbershop Musikfestival 2024 heißt uns Dortmund wieder herzlich willkommen.',
    key3: 'Folgend finden Sie Informationen zu den neuen Veranstaltungsorten sowie den Hotels in der Nähe.',
    key4: 'Veranstaltungsorte',
    key5: 'Für aktive Teilnehmer:innen und deren Begleitungen (Supporter) gibt es eigene Hotelkontingente. Um diese Kontingente abzurufen, muss nur Kontakt mit dem jeweiligen Hotel aufgenommen werden und mit dem jeweiligen Buchungscode reserviert werden.',
    key6: 'Eine Preisliste sowie die nötigen Buchungscodes werden per E-Mail an alle Mitglieder verschickt!',
    key7: 'ANREISE',
    key8: 'Auf der ',
    key9: 'Webseite der Westfalenhalle',
    key10: ' finden Sie ausführliche Infos zur Anreise zum Veranstaltungsort.',
    key11a: 'Auf ',
    key11b: 'www.bus-und-bahn.de',
    key11c: ' finden Sie den aktullen Fahrplan sowie Informationen zu Tickets.',
    key11: 'Folgend noch Informationen für die Anreise nach Dortmund:',
    key12: 'MIT DER BAHN',
    key13:
      'Innerhalb Deutschlands bietet sich die Deutsche Bahn als Transportmittel an. Sie bietet von folgenden Städten Direktverbindungen bzw. Verbindungen mit max. einmal Umsteigen.',
    key14: 'ca. 6 Std.',
    key15: 'ca. 3,5 Std.',
    key16: 'ca. 2,5 Std.',
    key17: 'ca. 2,5 Std.',
    key18: 'ca. 6 Std.',
    key19: 'MIT DEM FLUGZEUG',
    key20:
      'Dortmund verfügt über einen Flughafen (DTM), der innerhalb des deutschsprachigen Raums von München und Wien aus angeflogen wird. Aus welchen europäischen Ländern angeflogen wird, können Sie der Homepage des Flughafens entnehmen. Vom Flughafen kommt man mit dem AirportExpress innerhalb von 25 Minuten zum Hauptbahnhof Dortmund.',
    key21: '',
    key22: '',
    key23: '',
  },
};
