export const copy = {
  'en': {
    'key1': 'ABOUT THE BARBERSHOP MUSIC FESTIVAL',
    'key2': ' is the motto that inspires choruses and quartets from all over Germany to gather at the Goldsaal Dortmund in April 2024 for the biggest German barbershop event of the year. Experience the German Babershop Championships and let yourself be carried away by intoxicating performances and the ringing a cappella sound of barbershop at our big Saturday evening show.',
    'key3': '',
    'key4': 'The Barbershop Music Festival 2024 is all about love - the love of music, the love of brilliant entertainment and the love of creating a fantastic and impressive time for you. Join us and feel the fire that burns in the hearts of 400 passionate singers. This is a cappella music that touches the heart!',
    'key5': 'Look forward to incredible sound, breathtaking music and thrilling performances!',
    'key6': 'Contact',
    'key7': 'Telephone',
    'key8': 'Website',
    'key9': 'To BinG! Homepage',
  },
  'de': {
    'key1': 'ÜBER DAS BARBERSHOP MUSIKFESTIVAL',
    'key2': ' lautet das Motto, unter dem sich im April 2024 zahlreiche Chöre und Quartette im Goldsaal Dortmund zum größten Barbershop-Event Deutschlands einfinden werden. Erleben Sie die deutschen Barbershop Meisterschaften und lassen Sie sich in der Abendshow am Samstag von berauschenden Performances und dem einzigartigen Barbershop A-cappella Sound mitreißen.',
    'key3': '',
    'key4': 'Das Barbershop Musikfestival 2024 steht ganz im Zeichen der Liebe - Liebe zur Musik, zu fulminantem Entertainment und der Liebe, Ihnen eine fantastische und beeindruckende Zeit zu bereiten. Kommen Sie mit auf eine Reise und spüren das Feuer, dass in über 400 passionierten Sänger:innen brennt. Das ist A-cappella-Musik, die das Herz berührt!',
    'key5': 'Freuen Sie sich auf unglaubliches Stimmvolumen, atemberaubende emotionale Höhepunkte und mitreißende Performances!',
    'key6': 'Kontakt',
    'key7': 'Telefon',
    'key8': 'Internet',
    'key9': 'Zur BinG! Homepage',
  },
}