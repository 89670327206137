import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../BackButton';
import { BingLogo } from '../BingLogo';
import { useLanguageContext } from '../context/Language/';

import './styles.scss';

export const Navigation = () => {
  const navigate = useNavigate();
  const routeChange = () => {
    navigate('/');
  }
  const { language, setLanguage } = useLanguageContext();
  const showBackBtn = window.location.pathname !== '/';

  return (
    <div className='navbar'>
      <div className='navbar-text-logo-wrapper'>
      {showBackBtn ? <div className='navbar-back-btn' onClick={() => routeChange()}><BackButton /></div> : ''}
        <div className='navbar-btn-wrapper'>
          <div className={`navbar-btn de ${language === 'de' ? 'selected' : 'not-selected'}`}  onClick={() => setLanguage('de')}>DE</div>
          <div className={`navbar-btn en ${language === 'en' ? 'selected' : 'not-selected'}`} onClick={() => setLanguage('en')}>EN</div>
        </div>
        <div className='navbar-logo-wrapper' onClick={() => routeChange()}>
          <BingLogo />
        </div>
      </div>
    </div>
  )
}