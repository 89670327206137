import { useLanguageContext } from '../context/Language/';
import { copy } from './language';

import './styles.scss';

export const Sponsors = () => {
  const { language } = useLanguageContext();
  const languageObj = copy[language];

  return (
    <div className='sponsors-wrapper'>
      <div className='sponsors-text'>{languageObj['key1']}</div>
      <div className='sponsors-images-wrapper'>
        <div className='sponsor-image-wrapper'>
          <div className='sponsors-image lmr'></div>
        </div>
        <div className='sponsor-image-wrapper'>
          <div className='sponsors-image kultur'></div>
        </div>
        <div className='sponsor-image-wrapper'>
          <div className='sponsors-image buro'></div>
        </div>
      </div>
    </div>
  );
};
