import React from 'react';
import { Button } from '../Button';
import { FbLogo } from '../FbLogo';
import { FlickrLogo } from '../FlickrLogo';
import { YtLogo } from '../YtLogo';
import { useLanguageContext } from '../context/Language';
import useViewport from '../../hooks/useViewport.js';
import { copy } from './language';

import './styles.scss';

export const Footer = () => {
  const { language } = useLanguageContext();
  const languageObj = copy[language];
  const style = {
    height: '48px',
    marginLeft: '0',
    marginTop: '2em',
    width: '70%',
  };

  const { width } = useViewport();

  return (
    <div className='footer-wrapper'>
      <div className='footer-left side'>
        <h3 className='footer-left-header'>{languageObj['key1']}</h3>
        <div className='footer-space-top narrow light'>
          <b className='bold'>»{'A Cappella Love'.toUpperCase()}«</b>
          {languageObj['key2']}
        </div>
        <div className='footer-space-top light narrow'>
          <div>{languageObj['key4']}</div>
        </div>
        <div className='footer-space-top light narrow'>
          <div>{languageObj['key5']}</div>
        </div>
      </div>
      <div className='footer-right side'>
        <div className='footer-leftside side'>
          <h3 className='footer-right-header'>
            {languageObj['key6'].toUpperCase()}
          </h3>
          <div className='footer-space-top light'>
            <div>BinG! Barbershop in Germany e. V.</div>
            <div>Saarlandstraße 124 a</div>
            <div>44139 Dortmund</div>
          </div>
          <div className='footer-space-top light'>
            <div>{languageObj['key7']}: +49 231 22 20 42 62</div>
            <div>{languageObj['key8']}: www.barbershop.de</div>
            <div>E-Mail: kontakt@barbershop.de</div>
          </div>
          <a
            className='footer-impressum'
            href='https://www.barbershop.de/de/impressum'
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='impressum-txt'>Impressum</div>
          </a>
          <a
            className='hp-btn'
            href='https://barbershop.de'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button
              className={'small'}
              style={style}
              text={languageObj['key9']}
            />
          </a>
          {width <= 1303 && (
            <div className='footer-last-child'>
              <div className='footer-logo-wrapper side'>
                <a
                  className='footer-fb'
                  href='https://www.facebook.com/Barbershop.in.Germany'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FbLogo />
                </a>
                <a
                  className='footer-yt pdg-left'
                  href='https://www.youtube.com/@bingbarbershopingermany3555'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <YtLogo />
                </a>
                <a
                  className='footer-flickr pdg-left'
                  href='https://www.flickr.com/photos/bing_barbershop/albums/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FlickrLogo />
                </a>
              </div>
              <a
                className='footer-ideler pdg-left'
                href='https://eric.ideler.nl/BM/2023'
                target='_blank'
                rel='noopener noreferrer'
              >
                <div>Foto Galerie Eric Ideler</div>
              </a>
            </div>
          )}
        </div>
        {width > 1303 && (
          <div className='footer-logo-wrapper'>
            <a
              className='footer-fb'
              href='https://www.facebook.com/Barbershop.in.Germany'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FbLogo />
            </a>
            <a
              className='footer-yt'
              href='https://www.youtube.com/@bingbarbershopingermany3555'
              target='_blank'
              rel='noopener noreferrer'
            >
              <YtLogo />
            </a>
            <a
              className='footer-flickr'
              href='https://www.flickr.com/photos/bing_barbershop/albums/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FlickrLogo />
            </a>
            <a
              className='footer-ideler'
              href='https://eric.ideler.nl/BM/2023'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div>Foto Galerie</div>
              <div>Eric Ideler</div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
