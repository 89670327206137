import React from 'react';

export const FbLogo = () => {
  return (
    <svg
      width={62}
      height={62}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62 31C62 13.8773 48.1227 0 31 0C13.8773 0 0 13.8773 0 31C0 45.5312 10.0145 57.7375 23.5164 61.0918V40.4695H17.1227V31H23.5164V26.9191C23.5164 16.3719 28.2875 11.4797 38.6531 11.4797C40.6148 11.4797 44.0055 11.8672 45.398 12.2547V20.8281C44.6715 20.7555 43.4 20.707 41.8137 20.707C36.7277 20.707 34.766 22.6324 34.766 27.6336V31H44.8895L43.1457 40.4695H34.7539V61.7699C50.1086 59.9172 62 46.8512 62 31Z"
        fill="#E67015"
      />
    </svg>
  )
}