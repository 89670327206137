export const copy = {
  en: {
    key1: 'Media',
    key2: "Sad you can't be there live at the BMF? Don't worry!",
    key3: 'As with every year, all the events will be recorded.',
    key4: 'Video recordings will be available on our ',
    key5: 'YouTube channel',
    key6: ' shortly after each event.',
    key7: 'Eric Ideler will be uploading the newest photos on his ',
    key8: 'webpage',
    key9: '.',
    key10: '',
    key11: '',
    key12: '',
    key13: '',
    key14: '',
    key15: '',
    key16: '',
    key17: '',
    key18: '',
    key19: '',
    key20: '',
    key21: '',
    key22: '',
    key23: '',
    key24: '',
    key25: '',
    key26: '',
    key27: '',
    key28: '',
  },
  de: {
    key1: 'Mediathek',
    key2: 'Ihr könnt leider nicht live beim BMF dabei sein? Keine Sorge!',
    key3: 'Wie jedes Jahr werden die Veranstaltungen mit Bild und Ton aufgezeichnet.',
    key4: 'Außerdem werden wir die Videoaufzeichnungen der Veranstaltungen zeitnah nach jedem Event in unserer ',
    key5: 'YouTube-Mediathek',
    key6: ' zur Verfügung stellen.',
    key7: 'Eric Ideler wird während des BMFs nach und nach die neusten Fotos auf seiner ',
    key8: 'Webseite',
    key9: ' hochladen.',
    key10: '',
    key11: '',
    key12: '',
    key13: '',
    key14: '',
    key15: '',
    key16: '',
    key17: '',
    key18: '',
    key19: '',
    key20: '',
    key21: '',
    key22: '',
    key23: '',
    key24: '',
    key25: '',
    key26: '',
    key27: '',
    key28: '',
  },
};
