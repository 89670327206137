import { useEffect, useLayoutEffect, useState } from 'react';
import { useLanguageContext } from '../context/Language/';
import { copy } from './language';
import { getPlaylistVideos } from '../../api';
import { YouTubeVideo } from '../YoutubeVideo';
import { makeid } from '../../helper';
import './styles.scss';

export const Playlist = () => {
  const [playlist, setPlaylist] = useState([]);
  const { language } = useLanguageContext();
  const languageObj = copy[language];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const getVideos = async () => {
      const videos = await getPlaylistVideos();
      console.log('rcw videos', videos.items);
      const playlist = videos.items
        .filter(
          (video) => !video.snippet.title.toLowerCase().includes('delete')
        )
        .map((video) => {
          const {
            snippet: {
              resourceId: { videoId },
              title,
            },
          } = video;
          return {
            title,
            videoId,
          };
        });

      setPlaylist(playlist);
    };
    getVideos();
  }, []);

  return (
    <div className='champ-wrapper mb-4'>
      <div className='champ-welcome-wrapper'>
        <h1 className='champ-header-txt'>
          {languageObj['key1'].toUpperCase()}
        </h1>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key2']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>{languageObj['key3']}</div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>
            {languageObj['key7']}
            <span className='orange'>
              <a
                className='ref-link'
                href={'https://eric.ideler.nl/BING'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {languageObj['key8']}
              </a>
            </span>
            {languageObj['key9']}
          </div>
        </div>
        <div className='champ-txt-wrapper'>
          <div className='champ-txt'>
            {languageObj['key4']}
            <span className='orange'>
              <a
                className='ref-link'
                href={
                  'https://www.youtube.com/playlist?list=PLzf4Vau7WL5nsBjWnH28xlB-0e306cSqk'
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                {languageObj['key5']}
              </a>
            </span>
            {languageObj['key6']}
          </div>
        </div>
        {playlist.length &&
          playlist?.map((pl) => {
            return (
              <div key={makeid(8)} className='playlist-video-wrapper'>
                <div className='playlist-video'>
                  <YouTubeVideo videoId={pl.videoId} />
                </div>
                <div className='playlist-video-text-wrapper'>
                  <h3 className='playlist-video-text-title'>
                    {pl.title?.toUpperCase()}
                  </h3>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
