import { useLayoutEffect } from 'react';
import { Event } from '../Event';
import { Hotel } from '../Hotel';
import { useLanguageContext } from '../context/Language/';
import { copy } from './language';

import './styles.scss';

export const TravelAccommodation = () => {
  const { language } = useLanguageContext();
  const languageObj = copy[language];
  const hotels = [
    {
      name: 'INTERCITY HOTEL',
      street: 'Katharinenstr. 9',
      city: '44137 Dortmund',
      phone: '+49 231-56368-0 ',
      email: 'dortmund@intercityhotel.com',
      url: 'https://www.intercityhotel.com',
      googleMapsLink: 'https://maps.app.goo.gl/gFH9nfBVafYmoBAX8',
    },
    {
      name: 'ESPLANADE DORTMUND',
      street: 'Burgwall 3',
      city: '44135 Dortmund',
      phone: '+49 231 5853-0',
      email: 'hotel@esplanade-dortmund.de',
      url: 'https://www.esplanade-dortmund.de',
      googleMapsLink: 'https://maps.app.goo.gl/S1fWrSssjpv1tca49',
    },
    {
      name: 'MERCURE CENTRUM',
      street: 'Olpe 2',
      city: '44135 Dortmund',
      phone: '+49 231-5432243',
      email: 'reservierung.dortmund@eventhotels.com',
      url: 'https://www.mercure.com',
      googleMapsLink: 'https://maps.app.goo.gl/p2LSYtZrcf6uin5F8',
    },
    {
      name: 'JUGENDHERBERGE DORTMUND',
      street: 'Silberstr. 24-26',
      city: '44137 Dortmund',
      phone: '+49 231-140074',
      email: 'jgh-dortmund@djh-wl.de',
      url: '',
      googleMapsLink: 'https://maps.app.goo.gl/c3JUYTaUu1FXTUJD6',
    },
    {
      name: 'HOTEL B&B',
      street: 'Burgwall 5',
      city: '44137 Dortmund',
      phone: '+49 231-58989970',
      email: 'dortmund-city@hotelbb.com',
      url: 'https://www.hotelbb.de',
      googleMapsLink: 'https://maps.app.goo.gl/8c4spwDWUn8Esy5t7',
    },
    {
      name: 'LEONARDO HOTELS',
      street: 'Burgwall 11-13',
      city: '44135 Dortmund',
      phone: '+49 211-7771977',
      email: 'reservations.nrw@leonardo-hotels.com',
      url: 'https://www.leonardo-hotels.com',
      googleMapsLink: 'https://maps.app.goo.gl/hR7njA1zjUNVLCHe8',
    },
    {
      name: 'COFFEE FELLOWS HOTEL',
      street: 'Schwarze-Brüder-Str. 1',
      city: '44137 Dortmund',
      phone: '+49 231-54509870',
      email: 'reservation@coffee-fellows-hotel.com',
      url: 'https://www.coffee-fellows.com/hotel',
      googleMapsLink: 'https://maps.app.goo.gl/BGj3jddGyrwLtZLv7',
    },
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className='travel-wrapper'>
      <div className='travel-header-wrapper'>
        <h1>{languageObj['key1'].toUpperCase()}</h1>
        <div>
          {languageObj['key2']}
          <br />
          {languageObj['key3']}
        </div>
      </div>
      <div className='travel-events-wrapper'>
        <h1 className='orange'>{languageObj['key4'].toUpperCase()}</h1>
        <Event place='first' />
        <Event place='second' />
      </div>
      <div className='travel-hotels-wrapper'>
        <h1 className='travel-hotels-title orange'>{'Hotels'.toUpperCase()}</h1>
        <div className='travel-hotels-info-txt'>{languageObj['key5']}</div>
        <div className='travel-hotels-info-txt-price'>
          {languageObj['key6']}
        </div>
        <div className='travels-hotel-list-wrapper'>
          {hotels.map((hotel, idx) => (
            <Hotel
              city={hotel.city}
              email={hotel.email}
              key={`${hotel.name}-${idx}`}
              name={hotel.name}
              phone={hotel.phone}
              street={hotel.street}
              url={hotel.url}
            />
          ))}
        </div>
      </div>
      <div className='accommodations-wrapper'>
        <h1 className='accommodations-title orange'>
          {languageObj['key7'].toUpperCase()}
        </h1>
        <div className='accommodations-txt small'>
          {languageObj['key8']}
          <a
            className='accommodations-link'
            href='https://www.kongress-dortmund.de/fuer-besucher/anreise-parken/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {languageObj['key9']}
          </a>
          {languageObj['key10']}
        </div>
        <div className='accommodations-txt small'>
          {languageObj['key11a']}
          <a
            className='accommodations-link'
            href='https://www.bus-und-bahn.de/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {languageObj['key11b']}
          </a>
          {languageObj['key11c']}
        </div>
        <div className='accommodations-txt small'>{languageObj['key11']}</div>
        <h3 className='accommodations-with'>
          {languageObj['key12'].toUpperCase()}
        </h3>
        <div className='accommodations-txt small'>{languageObj['key13']}</div>
        <div className='accommodations-txt'>
          München - {languageObj['key14']}
        </div>
        <div className='accommodations-txt'>
          Berlin - {languageObj['key15']}
        </div>
        <div className='accommodations-txt'>
          Frankfurt - {languageObj['key16']}
        </div>
        <div className='accommodations-txt'>
          Hamburg - {languageObj['key17']}
        </div>
        <div className='accommodations-txt'>
          Dresden - {languageObj['key18']}
        </div>
        <h3 className='accommodations-with last'>
          {languageObj['key19'].toUpperCase()}
        </h3>
        <div className='accommodations-txt small'>{languageObj['key20']}</div>
      </div>
    </div>
  );
};
