export const copy = {
  en: {
    key1: 'LEND A HAND!',
    key2: 'There is always so much that needs to get done and we are grateful for support in big and small ways. Want to help? Write us a message at:',
  },
  de: {
    key1: 'Helfen Sie Mit!',
    key2: 'Es gibt immer viel tun tun und wir freuen uns über weitere Unterstützung – für kleine und größere Aufgaben. Sie haben Lust zu helfen? Melden Sie sich gerne per E-Mail:',
  },
};
