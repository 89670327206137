export const copy = {
  en: {
    key1: 'FESTIVAL OPEN STAGE',
    key2: "In addition to the German Championships, BinG! Barbershop in Germany e.V. is also hosting it's first ever Open Stage event this year.",
    key3: 'As with the German Championships, there will be an event for choirs and quartets in which each ensemble will perform two pieces in front of a jury. The participants receive an evaluation of their performance, but wil not be ranked against the other participants.',
    key4: 'As with the German Championships, the ensembles will be judged by the ruleset ',
    key5: 'found here',
    key6: '. However, the judges evaluate the open stage performances from a slightly different perspective.',
    key7: 'What does this mean? ',
    key8: 'It does not have to be pure barbershop. Barbershop-specific rules (melody must be mainly in the 2nd voice from the top, harmonies follow the circle of fifths, etc.) are not taken into account.',
    key9: 'How does this apply?',
    key10:
      'Quartets must sing in 4-part harmony. However, the melody can be in the tenor, baritone or bass. Choirs can also sing in more than 4 parts.',
    key11:
      'Vocal percussion is permitted if it does not require electronic amplification.',
    key12: 'Jazz harmonies are permitted. Instruments are not allowed.',
    key13: 'ORDER OF APPEARANCE',
    key14: 'Festival Open Stage for quartets',
    key15: 'Friday, 04/19/24, 11 am',
    key16: 'Festival Open Stage for choruses',
    key17: 'Saturday, 04/20/24, 11 am',
    key18:
      'The judging panel for this years competition is made up as follows:',
    key19: 'Judges 2024',
    key20:
      'A song with non-English lyrics may be difficult for the performance judges to judge, as it is very important for them to assess how well the message of the song has been communicated.',
    key21:
      'Each ensemble will receive an evaluation of their performance. Since the Open Stage is not a competition, ensembles will not be ranked.',
    key22: ' (scratched)',
    key23: '',
    key24: '',
    key25: '',
    key26: '',
    key27: '',
    key28: '',
  },
  de: {
    key1: 'FESTIVAL OPEN STAGE',
    key2: 'Neben den Deutschen Meisterschaften bietet BinG! Barbershop in Germany e.V. in diesem Jahr zum ersten Mal auch die Open Stage an.',
    key3: 'Wie bei den Deutschen Meisterschaften gibt für Chöre und Quartette jeweils ein Event bei dem sie zwei Stücke vor einer Jury zum Besten geben. Die Teilnehmer bekommen eine Bewertung ihrer Performance, treten aber nicht gegen die anderen teilnehmenden Ensembles an. Es gibt also am Ende keine Rangfolge.',
    key4: 'Wie bei den Deutschen Meisterschaften wird auch hier ',
    key5: 'folgendes Regelwerk',
    key6: ' zugrunde gelegt. Allerdings bewertet die Jury die Darbietungen aus einer leicht angepassten Perspektive.',
    key7: 'Was ist anders?',
    key8: 'Es muss nicht reiner Barbershop sein. Barbershop-spezifische Regelungen (Melodie muss hauptsächlich in der 2. Stimme von oben liegen, Harmonien folgen dem Quintenzirkel etc.) werden hier nicht berücksichtigt.',
    key9: 'Daraus folgt:',
    key10:
      'Für Quartette bleibt es 4 stimmig. Die Melodie kann aber im Tenor, Bariton oder Bass liegen. Chöre können auch mehr als 4 stimmig singen.',
    key11:
      'Vocal Percussion ist erlaubt, wenn sie ohne elektronische Verstärkung auskommt.',
    key12:
      'Es können auch Jazz Harmonien gesungen werden. Instrumente sind nicht erlaubt.',
    key13: 'AUFTRITTSREIHENFOLGE',
    key14: 'Festival Open Stage - Quartette',
    key15: 'Freitag, 19.04.24, 11:00 Uhr',
    key16: 'Festival Open Stage - Chöre',
    key17: 'Samstag, 20.04.24, 11:00 Uhr',
    key18:
      'Die Jury für die Wettbewerbe setzt sich dieses Jahr wie folgt zusammen:',
    key19: 'Jury 2024',
    key20:
      'Ein Lied mit nicht englischem Text, könnte für die Performance Judges schwierig zu bewerten sein, da es wichtig ist zu beurteilen wie gut die Botschaft des Liedes kommuniziert wurde.',
    key21:
      'Jedes Ensemble bekommt eine Bewertung ihrer Performance. Da die Open Stage kein Wettbewerb ist, wird es auch keine Rangfolge geben.',
    key22: ' (abgesagt)',
    key23: '',
    key24: '',
    key25: '',
    key26: '',
    key27: '',
    key28: '',
  },
};
