import React from 'react';

export const YtLogo = () => {
  return (
    <svg
      width={68}
      height={48}
      viewBox="0 0 68 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.8038 8.02773C65.0382 5.15781 62.7899 2.90547 59.934 2.14258C54.7569 0.75 34 0.75 34 0.75C34 0.75 13.2431 0.75 8.06597 2.14258C5.21007 2.90547 2.9618 5.15781 2.19618 8.02773C0.81076 13.2227 0.81076 24.0484 0.81076 24.0484C0.81076 24.0484 0.81076 34.8742 2.19618 40.0691C2.9618 42.9391 5.21007 45.0945 8.06597 45.8574C13.2431 47.25 34 47.25 34 47.25C34 47.25 54.7569 47.25 59.934 45.8574C62.7899 45.0945 65.0382 42.927 65.8038 40.0691C67.1892 34.8742 67.1892 24.0484 67.1892 24.0484C67.1892 24.0484 67.1892 13.2227 65.8038 8.02773ZM27.2187 33.8813V14.2156L44.5608 24.0484L27.2187 33.8813Z"
        fill="#E67015"
      />
    </svg>
  )
}