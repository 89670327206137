import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Championship } from './components/Championship';
import { Construction } from './components/Construction';
import { Home } from './components/Home';
import { Footer } from './components/Footer';
import { Navigation } from './components/Navigation';
import { OpenStage } from './components/OpenStage';
import { PageNotFound } from './components/PageNotFound';
import { Playlist } from './components/Playlist';
import { Sponsors } from './components/Sponsors';
import { Tickets } from './components/Tickets';
import { TravelAccommodation } from './components/TravelAccommodation';
import { LanguageProvider } from './components/context/Language';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LanguageProvider>
        <Navigation />
        <Routes>
          {process.env.REACT_APP_IS_LIVE === 'live' && (
            <>
              <Route path='/' element={<Home />} />
              <Route
                path='/travel-accommodations'
                element={<TravelAccommodation />}
              />
              <Route path='/register' element={<Tickets />} />
              <Route path='/tickets' element={<Tickets />} />
              <Route path='/media' element={<Playlist />} />
              <Route
                path='/deutsche-meisterschaften'
                element={<Championship />}
              />
              <Route path='/open-stage' element={<OpenStage />} />
            </>
          )}
          {(!process.env.REACT_APP_IS_LIVE ||
            process.env.REACT_APP_IS_LIVE === 'not-live') && (
            <Route path='/' element={<Construction />} />
          )}
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        <Sponsors />
        <Footer />
      </LanguageProvider>
    </BrowserRouter>
  </React.StrictMode>
);
