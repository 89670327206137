export const copy = {
  en: {
    key1: 'Thanks to our sponsors',
    key2: '',
    key3: '',
    key4: '',
    key5: '',
    key6: '',
    key7: '',
    key8: '',
    key9: '',
    key10: '',
    key11: '',
    key12: '',
    key13: '',
    key14: '',
    key15: '',
    key16: '',
    key17: '',
    key18: '',
    key19: '',
    key20: '',
    key21: '',
    key22: '',
    key23: '',
    key24: '',
    key25: '',
    key26: '',
    key27: '',
    key28: '',
  },
  de: {
    key1: 'Mit freundlicher Unterstützung von',
    key2: '',
    key3: '',
    key4: '',
    key5: '',
    key6: '',
    key7: '',
    key8: '',
    key9: '',
    key10: '',
    key11: '',
    key12: '',
    key13: '',
    key14: '',
    key15: '',
    key16: '',
    key17: '',
    key18: '',
    key19: '',
    key20: '',
    key21: '',
    key22: '',
    key23: '',
    key24: '',
    key25: '',
    key26: '',
    key27: '',
    key28: '',
  },
};
