export const copy = {
  'en': {
    'key1': 'German championships',
    'key2': 'and Festival Open Stage',
    'key3': 'FRIDAY - SUNDAY',
    'key4': 'LOCATION FOR THE',
    'key5': 'Still in the works. Please check back in later',
    'key6': 'THURSDAY',
    'key7': 'Get Together',
    'key8': 'THURSDAY',
  },
  'de': {
    'key1': 'Deutsche Meisterschaften',
    'key2': ' und Festival Open Stage',
    'key3': 'FREITAG - SONNTAG',
    'key4': 'LOCATION FÜR DAS',
    'key5': 'Infos folgen in Kürze',
    'key6': 'DONNERSTAG',
    'key7': 'Get Together',
    'key8': 'THURSDAY',
  },
}