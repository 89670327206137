import React from 'react';

import './styles.scss';

export const BingLogo = () => (
  <div className='bing-logo-wrapper'>
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 393.1 196.9"
      style={{
        enableBackground: "new 0 0 393.1 196.9",
      }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {
          "\n\t.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}\n\t.st1{fill:#FFFFFF;}\n"
        }
      </style>
      <g>
        <path
          className="st0"
          d="M128.6,53.9h6.6c5.2,0,8.4-2.5,10.1-7.4c0.8-2.3,1-4.8,1-7.2c0-3.7-0.7-7.7-3.3-10.5c-2.1-2.2-4.8-3.2-7.8-3.2 h-6.6V53.9L128.6,53.9z M128.6,107.9h6.6c6,0,9.6-3.3,11.6-8.7c0.9-2.3,1.2-4.7,1.2-7.1c0-4.5-0.6-10.4-3.6-14 c-2.3-2.8-5.7-3.8-9.2-3.8h-6.6V107.9L128.6,107.9z M157.2,63.6c3.6,2,6.3,5,8.5,8.6c2.3,3.8,3.6,8.3,4.4,12.6 c0.5,2.9,0.7,5.8,0.7,8.7c0,7-1.2,14.1-4.6,20.3c-2.4,4.5-5.8,8-10.1,10.7c-4.5,2.7-9.4,4.5-14.6,4.5h-35.5V4.1h29 c7.4,0,14.2,1.9,20.4,6c5.7,3.7,9.2,9.4,11.3,15.9c1.4,4.3,1.8,8.9,1.8,13.4c0,7.5-1.8,14.8-6.9,20.6 C160.3,61.4,158.8,62.6,157.2,63.6L157.2,63.6z"
        />
        <polygon
          className="st0"
          points="203.2,129 181.2,129 181.2,32.7 203.2,32.7 203.2,129  "
        />
        <path
          className="st0"
          d="M239.9,38.8c5.5-6,16.8-8.2,24.6-5.2C275.7,37.9,281,48.3,281,60v69H260V59.6c0-5.5-4.5-10.1-10.1-10.1l0,0 c-5.5,0-10.1,4.5-10.1,10.1V129h-21.9V32.6h21.9V38.8L239.9,38.8z"
        />
        <path
          className="st0"
          d="M331.9,77.2h-9.5V57.4h10.4h21.1V100c0,8.4-3,15.7-9,21.6c-6,5.9-13.2,8.9-21.5,8.9c-8.5,0-15.7-3-21.7-9 c-5.9-5.9-8.9-13.1-8.9-21.5V33.4c0-8.5,3-15.7,9-21.7c6-5.9,13.2-8.9,21.6-8.9c8.4,0,15.7,3,21.6,9c5.9,6,8.9,13.2,8.9,21.6v13.9 h-15.5h-6.4V33.1c0-2.5-0.9-4.7-2.7-6.5c-1.8-1.8-4-2.7-6.5-2.7c-2.5,0-4.7,0.9-6.4,2.7c-1.8,1.8-2.7,4-2.7,6.5v66.8 c0,2.5,0.9,4.7,2.6,6.4c1.8,1.8,3.9,2.6,6.4,2.6c2.5,0,4.7-0.9,6.5-2.7c1.8-1.8,2.7-3.9,2.7-6.4V77.2L331.9,77.2z"
        />
        <path
          className="st0"
          d="M192.2,0c7,0,12.7,5.7,12.7,12.7c0,7-5.7,12.7-12.7,12.7c-7,0-12.7-5.7-12.7-12.7C179.5,5.7,185.2,0,192.2,0 L192.2,0z"
        />
        <path
          className="st0"
          d="M378.4,107.1c7,0,12.7,5.7,12.7,12.7c0,7-5.7,12.7-12.7,12.7c-7,0-12.7-5.7-12.7-12.7 C365.7,112.8,371.4,107.1,378.4,107.1L378.4,107.1z"
        />
        <polygon
          className="st0"
          points="368.7,4.1 390,4.1 390,98.7 368.7,98.7 368.7,4.1  "
        />
        <path
          className="st0"
          d="M48.2,57l35.1-9.4v12.7c0,5-3.8,10-8.4,11.3L39.8,81V68.2C39.8,63.3,43.5,58.2,48.2,57L48.2,57z"
        />
        <path
          className="st0"
          d="M48.2,13.5l35.1-9.4v12.7c0,5-3.8,10-8.4,11.3l-35.1,9.4V24.8C39.8,19.8,43.5,14.7,48.2,13.5L48.2,13.5z"
        />
        <path
          className="st0"
          d="M48.2,100.5l35.1-9.4v12.7c0,5-3.8,10-8.4,11.3l-31.6,8.5l0,52.7c0,6.8-5.8,14.1-16.6,18.1 c-10.8,4-23.3,1.7-26.1-5.9c-2.8-7.6,5.6-17.6,16-21.5c10.4-3.8,18.4-2.5,23.2,1.8v-44.3v-3.5v-9.2 C39.8,106.8,43.5,101.7,48.2,100.5L48.2,100.5z"
        />
        <polygon
          className="st1"
          points="393.1,194.7 393.1,196.9 376.5,196.9 376.5,176.6 380.7,176.6 380.7,194.7 393.1,194.7  "
        />
        <path
          className="st1"
          d="M364,176.6l8.2,20.3h-4.2l-1.9-4.9h-11l-1.9,4.9h-4.2l8.2-20.3H364L364,176.6z M356.2,189.8h9l-4.3-10.7h-0.3 L356.2,189.8L356.2,189.8z"
        />
        <polygon
          className="st1"
          points="337.1,194.4 337.4,194.4 344.6,176.6 348.9,176.6 340.7,196.9 333.7,196.9 325.5,176.6 329.8,176.6  337.1,194.4  "
        />
        <polygon
          className="st1"
          points="320.6,196.9 316.4,196.9 316.4,176.6 320.6,176.6 320.6,196.9  "
        />
        <polygon
          className="st1"
          points="312.2,178.8 304.3,178.8 304.3,196.9 300.1,196.9 300.1,178.8 292.3,178.8 292.3,176.6 312.2,176.6  312.2,178.8  "
        />
        <path
          className="st1"
          d="M290.8,191.5c0,0.8-0.2,1.6-0.5,2.3c-0.3,0.7-0.9,1.4-1.7,1.9c-0.8,0.5-1.8,1-3,1.3c-1.2,0.3-2.8,0.5-4.6,0.5 c-1,0-2-0.1-3-0.2c-1-0.2-1.9-0.4-2.8-0.7c-0.9-0.3-1.7-0.6-2.4-1.1c-0.7-0.4-1.4-0.9-1.9-1.4l1.4-1.7c1.1,0.9,2.4,1.7,3.9,2.2 c1.5,0.5,3.1,0.7,4.8,0.7c1.9,0,3.3-0.3,4.1-0.8c0.8-0.5,1.2-1.3,1.2-2.2c0-0.7-0.3-1.4-1-1.9c-0.6-0.5-1.7-1-3.2-1.5l-5.5-1.7 c-1.8-0.6-3.2-1.3-4.1-2.2c-0.9-0.9-1.4-2-1.4-3.4c0-0.7,0.2-1.4,0.5-2.1c0.3-0.7,0.9-1.3,1.6-1.8c0.7-0.5,1.6-0.9,2.7-1.2 c1.1-0.3,2.4-0.4,4-0.4c0.9,0,1.9,0.1,2.8,0.2c0.9,0.2,1.8,0.4,2.7,0.7c0.9,0.3,1.7,0.6,2.4,1.1c0.7,0.4,1.4,0.9,1.9,1.4l-1.4,1.7 c-1.1-0.9-2.3-1.7-3.7-2.2c-1.4-0.5-2.9-0.7-4.7-0.7c-1.4,0-2.5,0.2-3.3,0.8c-0.7,0.5-1.1,1.1-1.1,1.9c0,0.4,0.1,0.8,0.2,1.1 c0.1,0.3,0.4,0.6,0.7,0.8c0.3,0.3,0.7,0.5,1.3,0.7c0.5,0.2,1.2,0.5,1.9,0.7l5.5,1.7c1.8,0.6,3.1,1.3,4.1,2.2 C290.3,189,290.8,190.2,290.8,191.5L290.8,191.5z"
        />
        <polygon
          className="st1"
          points="253.8,178.8 253.8,185.5 264.3,185.5 264.3,187.6 253.8,187.6 253.8,194.7 267.2,194.7 267.2,196.9  249.6,196.9 249.6,176.6 267.2,176.6 267.2,178.8 253.8,178.8  "
        />
        <polygon
          className="st1"
          points="231.7,178.8 231.7,185.8 242.3,185.8 242.3,187.9 231.7,187.9 231.7,196.9 227.5,196.9 227.5,176.6  245.8,176.6 245.8,178.8 231.7,178.8  "
        />
        <polygon
          className="st1"
          points="215.6,186.6 223.3,196.9 218.5,196.9 211.9,187.9 206.8,187.9 206.8,196.9 202.6,196.9 202.6,176.6  206.8,176.6 206.8,185.8 211.6,185.8 218.3,176.6 222.9,176.6 215.6,186.6  "
        />
        <polygon
          className="st1"
          points="195.2,196.9 191,196.9 191,176.6 195.2,176.6 195.2,196.9  "
        />
        <path
          className="st1"
          d="M185.5,191.5c0,0.8-0.2,1.6-0.5,2.3c-0.3,0.7-0.9,1.4-1.7,1.9c-0.8,0.5-1.8,1-3,1.3c-1.2,0.3-2.8,0.5-4.6,0.5 c-1,0-2-0.1-3-0.2c-1-0.2-1.9-0.4-2.8-0.7c-0.9-0.3-1.7-0.6-2.4-1.1c-0.7-0.4-1.4-0.9-1.9-1.4l1.4-1.7c1.1,0.9,2.4,1.7,3.9,2.2 c1.5,0.5,3.1,0.7,4.8,0.7c1.9,0,3.3-0.3,4.1-0.8c0.8-0.5,1.2-1.3,1.2-2.2c0-0.7-0.3-1.4-1-1.9c-0.6-0.5-1.7-1-3.2-1.5l-5.5-1.7 c-1.8-0.6-3.2-1.3-4.1-2.2c-0.9-0.9-1.4-2-1.4-3.4c0-0.7,0.2-1.4,0.5-2.1c0.3-0.7,0.9-1.3,1.6-1.8c0.7-0.5,1.6-0.9,2.7-1.2 c1.1-0.3,2.4-0.4,4-0.4c0.9,0,1.9,0.1,2.8,0.2c0.9,0.2,1.8,0.4,2.7,0.7c0.9,0.3,1.7,0.6,2.4,1.1c0.7,0.4,1.4,0.9,1.9,1.4l-1.4,1.7 c-1.1-0.9-2.3-1.7-3.7-2.2c-1.4-0.5-2.9-0.7-4.7-0.7c-1.4,0-2.5,0.2-3.3,0.8c-0.7,0.5-1.1,1.1-1.1,1.9c0,0.4,0.1,0.8,0.2,1.1 c0.1,0.3,0.4,0.6,0.7,0.8c0.3,0.3,0.7,0.5,1.3,0.7c0.5,0.2,1.2,0.5,1.9,0.7l5.5,1.7c1.8,0.6,3.1,1.3,4.1,2.2 C185,189,185.5,190.2,185.5,191.5L185.5,191.5z"
        />
        <path
          className="st1"
          d="M150.4,195.5c0.9,0,1.8-0.1,2.6-0.4c0.8-0.3,1.4-0.7,2-1.2c0.5-0.5,1-1.3,1.3-2.1c0.3-0.9,0.4-1.9,0.4-3.1v-12 h4.2v12c0,3-0.9,5.2-2.7,6.6c-1.8,1.5-4.4,2.2-7.7,2.2c-3.3,0-5.9-0.7-7.7-2.2c-1.8-1.5-2.7-3.7-2.7-6.6v-12h4.2v12 c0,1.2,0.1,2.2,0.4,3.1c0.3,0.9,0.7,1.6,1.3,2.1c0.5,0.5,1.2,1,2,1.2C148.6,195.3,149.4,195.5,150.4,195.5L150.4,195.5z"
        />
        <polygon
          className="st1"
          points="119.9,194 120.2,194 127.8,176.6 134,176.6 134,196.9 129.8,196.9 129.8,180.7 129.5,180.7  122.5,196.9 117.6,196.9 110.7,180.7 110.4,180.7 110.4,196.9 106.2,196.9 106.2,176.6 112.4,176.6 119.9,194  "
        />
        <path
          className="st1"
          d="M375.6,146.6v9.8h6.2c1.5,0,2.7-0.4,3.5-1.2c0.8-0.8,1.3-2,1.3-3.7c0-1.7-0.4-2.9-1.3-3.7 c-0.8-0.8-2-1.2-3.5-1.2H375.6L375.6,146.6z M381.9,144.4c1.4,0,2.7,0.2,3.9,0.5c1.1,0.3,2.1,0.8,2.9,1.4c0.8,0.6,1.4,1.4,1.8,2.2 c0.4,0.9,0.6,1.9,0.6,3c0,1.1-0.2,2.1-0.6,3c-0.4,0.9-1,1.6-1.8,2.2c-0.8,0.6-1.8,1.1-2.9,1.4c-1.1,0.3-2.4,0.5-3.9,0.5h-6.2v6.1 h-4.2v-20.3H381.9L381.9,144.4z"
        />
        <path
          className="st1"
          d="M350.1,143.8c1.7,0,3.3,0.2,4.7,0.7c1.4,0.5,2.6,1.1,3.6,2.1c1,0.9,1.8,2,2.4,3.4c0.6,1.3,0.8,2.9,0.8,4.6 c0,1.7-0.3,3.3-0.8,4.6c-0.6,1.3-1.3,2.5-2.4,3.4c-1,0.9-2.2,1.6-3.6,2.1c-1.4,0.5-3,0.7-4.7,0.7c-1.7,0-3.3-0.2-4.8-0.7 c-1.4-0.5-2.6-1.1-3.7-2.1c-1-0.9-1.8-2-2.3-3.4c-0.6-1.3-0.8-2.9-0.8-4.6c0-1.7,0.3-3.3,0.8-4.6c0.5-1.3,1.3-2.5,2.3-3.4 c1-0.9,2.2-1.6,3.7-2.1C346.8,144.1,348.4,143.8,350.1,143.8L350.1,143.8z M350.1,145.9c-1,0-1.9,0.2-2.8,0.5 c-0.9,0.3-1.6,0.9-2.2,1.6c-0.6,0.7-1.1,1.6-1.5,2.7c-0.4,1.1-0.6,2.4-0.6,3.9c0,1.5,0.2,2.8,0.6,3.9c0.4,1.1,0.9,2,1.5,2.7 c0.6,0.7,1.4,1.2,2.2,1.6c0.9,0.3,1.8,0.5,2.8,0.5s1.9-0.2,2.8-0.5c0.9-0.3,1.6-0.9,2.2-1.6c0.6-0.7,1.1-1.6,1.5-2.7 c0.4-1.1,0.6-2.4,0.6-3.9c0-1.5-0.2-2.8-0.6-3.9c-0.4-1.1-0.9-2-1.5-2.7c-0.6-0.7-1.4-1.2-2.2-1.6C352,146,351.1,145.9,350.1,145.9 L350.1,145.9z"
        />
        <polygon
          className="st1"
          points="328.8,144.4 328.8,164.7 324.6,164.7 324.6,155.8 312.8,155.8 312.8,164.7 308.6,164.7 308.6,144.4  312.8,144.4 312.8,153.7 324.6,153.7 324.6,144.4 328.8,144.4  "
        />
        <path
          className="st1"
          d="M299.4,159.3c0,0.8-0.2,1.6-0.5,2.3c-0.3,0.7-0.9,1.4-1.7,1.9c-0.8,0.5-1.8,1-3,1.3c-1.2,0.3-2.8,0.5-4.6,0.5 c-1,0-2-0.1-3-0.2c-1-0.2-1.9-0.4-2.8-0.7c-0.9-0.3-1.7-0.6-2.4-1.1c-0.7-0.4-1.4-0.9-1.9-1.4l1.4-1.7c1.1,0.9,2.4,1.7,3.9,2.2 c1.5,0.5,3.1,0.7,4.8,0.7c1.9,0,3.3-0.3,4.1-0.8c0.8-0.5,1.2-1.3,1.2-2.2c0-0.7-0.3-1.4-1-1.9c-0.6-0.5-1.7-1-3.2-1.5l-5.5-1.7 c-1.8-0.6-3.2-1.3-4.1-2.2c-0.9-0.9-1.4-2-1.4-3.4c0-0.7,0.2-1.4,0.5-2.1c0.3-0.7,0.9-1.3,1.6-1.8c0.7-0.5,1.6-0.9,2.7-1.2 c1.1-0.3,2.4-0.4,4-0.4c0.9,0,1.9,0.1,2.8,0.2c0.9,0.2,1.8,0.4,2.7,0.7c0.9,0.3,1.7,0.6,2.4,1.1c0.7,0.4,1.4,0.9,1.9,1.4l-1.4,1.7 c-1.1-0.9-2.3-1.7-3.7-2.2c-1.4-0.5-2.9-0.7-4.7-0.7c-1.4,0-2.5,0.3-3.3,0.8c-0.7,0.5-1.1,1.1-1.1,1.9c0,0.4,0.1,0.8,0.2,1.1 c0.1,0.3,0.4,0.6,0.7,0.8c0.3,0.3,0.7,0.5,1.3,0.7c0.5,0.2,1.2,0.5,1.9,0.7l5.5,1.7c1.8,0.6,3.1,1.3,4.1,2.2 C298.9,156.8,299.4,157.9,299.4,159.3L299.4,159.3z"
        />
        <path
          className="st1"
          d="M255.4,146.6v9.8h6.2c1.5,0,2.7-0.4,3.5-1.2c0.8-0.8,1.2-2,1.2-3.7c0-1.7-0.4-2.9-1.2-3.7 c-0.8-0.8-2-1.2-3.5-1.2H255.4L255.4,146.6z M261.6,144.4c1.4,0,2.7,0.2,3.9,0.5c1.1,0.3,2.1,0.8,2.9,1.4c0.8,0.6,1.4,1.4,1.8,2.2 c0.4,0.9,0.6,1.9,0.6,3c0,1.5-0.4,2.8-1.2,3.9c-0.8,1.1-1.9,1.9-3.3,2.4l4.8,6.8h-4.8l-4.3-6.1h-0.5h-6.2v6.1h-4.2v-20.3H261.6 L261.6,144.4z"
        />
        <polygon
          className="st1"
          points="228.6,146.6 228.6,153.2 239.2,153.2 239.2,155.4 228.6,155.4 228.6,162.5 242.1,162.5 242.1,164.7  224.4,164.7 224.4,144.4 242.1,144.4 242.1,146.6 228.6,146.6  "
        />
        <path
          className="st1"
          d="M204.8,153.2c2.8,0,4.2-1.2,4.2-3.5c0-1-0.4-1.8-1.1-2.4c-0.7-0.5-1.8-0.8-3.1-0.8h-5.6v6.7H204.8L204.8,153.2 z M199.2,155.4v7.1h6.5c1.4,0,2.6-0.3,3.3-0.9c0.8-0.6,1.2-1.4,1.2-2.6c0-1.2-0.4-2.1-1.2-2.7c-0.8-0.6-1.9-0.9-3.3-0.9H199.2 L199.2,155.4z M195,144.4h9.8c1.5,0,2.8,0.1,3.9,0.4c1.1,0.3,2,0.6,2.7,1.1c0.7,0.5,1.2,1,1.6,1.7c0.3,0.7,0.5,1.4,0.5,2.2 c0,1-0.3,1.9-0.9,2.7c-0.6,0.8-1.4,1.3-2.6,1.7c1.4,0.3,2.6,0.9,3.4,1.7c0.8,0.8,1.2,1.9,1.2,3.2c0,1.7-0.7,3.1-2.2,4.1 c-1.4,1-3.7,1.5-6.8,1.5H195V144.4L195,144.4z"
        />
        <path
          className="st1"
          d="M169.8,146.6v9.8h6.2c1.5,0,2.7-0.4,3.5-1.2c0.8-0.8,1.2-2,1.2-3.7c0-1.7-0.4-2.9-1.2-3.7 c-0.8-0.8-2-1.2-3.5-1.2H169.8L169.8,146.6z M176,144.4c1.4,0,2.7,0.2,3.9,0.5c1.1,0.3,2.1,0.8,2.9,1.4c0.8,0.6,1.4,1.4,1.8,2.2 c0.4,0.9,0.6,1.9,0.6,3c0,1.5-0.4,2.8-1.2,3.9c-0.8,1.1-1.9,1.9-3.3,2.4l4.8,6.8h-4.8l-4.3-6.1H176h-6.2v6.1h-4.2v-20.3H176 L176,144.4z"
        />
        <path
          className="st1"
          d="M148.8,144.4l8.3,20.3h-4.2l-1.9-4.9h-11l-1.9,4.9h-4.2l8.3-20.3H148.8L148.8,144.4z M140.9,157.6h9l-4.3-10.7 h-0.3L140.9,157.6L140.9,157.6z"
        />
        <path
          className="st1"
          d="M116,153.2c2.8,0,4.2-1.2,4.2-3.5c0-1-0.4-1.8-1.1-2.4c-0.7-0.5-1.8-0.8-3.1-0.8h-5.6v6.7H116L116,153.2z  M110.4,155.4v7.1h6.5c1.4,0,2.6-0.3,3.3-0.9c0.8-0.6,1.2-1.4,1.2-2.6c0-1.2-0.4-2.1-1.2-2.7c-0.8-0.6-1.9-0.9-3.3-0.9H110.4 L110.4,155.4z M106.2,144.4h9.8c1.5,0,2.8,0.1,3.9,0.4c1.1,0.3,2,0.6,2.7,1.1c0.7,0.5,1.2,1,1.6,1.7c0.3,0.7,0.5,1.4,0.5,2.2 c0,1-0.3,1.9-0.9,2.7c-0.6,0.8-1.4,1.3-2.6,1.7c1.4,0.3,2.6,0.9,3.4,1.7c0.8,0.8,1.2,1.9,1.2,3.2c0,1.7-0.7,3.1-2.2,4.1 c-1.4,1-3.7,1.5-6.8,1.5h-10.7V144.4L106.2,144.4z"
        />
      </g>
    </svg>
  </div>
);
